


<!-- Project One Row -->
<div class="row justify-content-center no-gutters mb-5 mb-lg-0">
<div class="col-lg-6">
    <img class="img-fluid" src="assets/img/kindney_background.jpg" alt="">
</div>
<div class="col-lg-6">
    <div class="bg-black text-center h-100 project">
    <div class="d-flex h-100">
        <div class="project-text w-100 my-auto text-center text-lg-left">
        <h4 class="text-white">AKI Diagnosis</h4>
        <p class="mb-0 text-white-50">A project that tried to predict AKI, or Akute Kidney Injury, in catastrophic situations, with cutting edge results. The work can be found <a href="https://github.com/pooriaPoorsarvi/Early-Detection-of-Acute-Kidney-Injury-in-Catastrophic-Incidents-through-Machine-Learning-Approache">here</a>.</p>
        <hr class="d-none d-lg-block mb-0 ml-0">
        </div>
    </div>
    </div>
</div>
</div>

<!-- Project Two Row -->
<div class="row justify-content-center no-gutters">
<div class="col-lg-6">
    <img class="img-fluid" src="assets/img/ta_background.jpg" alt="">

</div>
<div class="col-lg-6 order-lg-first">
    <div class="bg-black text-center h-100 project">
    <div class="d-flex h-100">
        <div class="project-text w-100 my-auto text-center text-lg-right">
        <h4 class="text-white">Teaching Assistant</h4>
        <p class="mb-0 text-white-50">The subjects that were taught as a teaching assistant at Shahid Behesthi University (Before Transferring To UofT) Were : Computational Inteligence, Aritifical Inteligence, Data Structures, Compilers, and Advanced Programming.</p>
        <hr class="d-none d-lg-block mb-0 mr-0">
        </div>
    </div>
    </div>
</div>
</div>
