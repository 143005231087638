
<ion-app>

  <ion-content [scrollEvents]="true" (ionScroll)="onScroll($event)">



  <app-nav-bar></app-nav-bar>

  <router-outlet></router-outlet>
  <!-- <app-portfolio></app-portfolio> -->

  <app-sign-up-section></app-sign-up-section>
  <app-contact-section #contacts></app-contact-section>
  <app-footer></app-footer>

  </ion-content>
</ion-app>
