
<!-- Signup Section -->
<section id="signup" class="signup-section">
<div class="container">
    <div class="row">
    <div class="col-md-10 col-lg-8 mx-auto text-center">

        <i class="fa fa-paper-plane fa-2x mb-2 text-white"></i>
        <h2 class="text-white mb-5">Subscribe to receive updates!</h2>

        <div class="form-inline d-flex">
        <input type="email" [formControl]="email" class="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"  placeholder="Enter email address...">
        <button type="button" class="btn btn-primary mx-auto" (click) = "signUp()" *ngIf = "buttonActive">{{buttonText}}</button>
        </div>

    </div>
    </div>
</div>
</section>
