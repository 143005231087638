
<!-- Contact Section -->
<section class="contact-section bg-black" >
<div class="container" #contacts id='contact'>

    <div class="row">

    <app-address class="col-md-6 mb-3 mb-md-0" ></app-address>
    <app-email class="col-md-6 mb-3 mb-md-0" ></app-email>
    <!-- <app-phone class="col-md-4 mb-3 mb-md-0" [phoneNumber] = "personalInfoService.getPersonalInfo().phoneNumber"></app-phone> -->

    </div>

    <app-sites></app-sites>

</div>
</section>


