<!-- TODO make this programatic and send it from the server -->
<!-- TODO find why ion-img messes up scrollintoview -->
<ion-grid class="ion-no-padding ion-no-margin">
  <ion-row class="ion-no-padding ion-no-margin">
    <ion-col size=12 class="ion-no-padding ion-no-margin" *ngFor = 'let sample of samples'>
      <ion-card class="ion-no-margin ion-margin-bottom ion-text-center">
        <ion-card-header color='secondary'>
          {{sample.title}}
        </ion-card-header>
        <img [src]='sample.imageSrc' class="img-fluid">
        <ion-card-content class="ion-margin-top">
          <div>
            <ion-text>
              {{sample.description}}
            </ion-text>
          </div>
          <div class="ion-margin-top">
            <a [href]="sample.urlLink">
              <ion-button color='primary'>
                Go to project
              </ion-button>
            </a>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>


