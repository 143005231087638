
<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav" #mainNav>
  <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">PPT Productions</a>
      <button class="navbar-toggler navbar-toggler-right" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation">
      Menu
      <i class="fa fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarsExampleDefault" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav ml-auto">
          <li class="nav-item">
          <a class="nav-link" (click)="gotoBlog()" >Blog</a>
          </li>
          <li class="nav-item">
          <a class="nav-link" (click)="goToProjects()" >Projects And Background</a>
          </li>
          <li class="nav-item">
          <a class="nav-link" (click)="goToContacts()">Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="goToApps()" >Apps</a>
          </li>
      </ul>
      </div>
  </div>
  </nav>
