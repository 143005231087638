
<div class="row align-items-center no-gutters mb-4 mb-lg-5">
  <div class="col-xl-8 col-lg-7">
    <!--  -->
      <!-- <img class="img-fluid mb-3 mb-lg-0" src="assets/img/bg-masthead.jpg" alt=""> -->
      <img class="img-fluid mb-3 mb-lg-0" [src]="image_src" alt="">
  </div>
  <div class="col-xl-4 col-lg-5">
    <ng-content></ng-content>
  </div>
</div>
