<div>
  <div class="card py-4 h-100">
  <div class="card-body text-center">
      <i class="fa fa-envelope text-primary mb-2"></i>
      <h4 class="text-uppercase m-0">Email</h4>
      <hr class="my-4">
      <div class="small text-black-50">
      <a href="#">{{emailAddress}}</a>
      </div>
  </div>
  </div>
</div>
