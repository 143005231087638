
<!-- Featured Project Row -->
<div class="row align-items-center no-gutters mb-4 mb-lg-5">
  <div class="col-xl-8 col-lg-7">
    <!--  -->
      <!-- <img class="img-fluid mb-3 mb-lg-0" src="assets/img/bg-masthead.jpg" alt=""> -->
      <img class="img-fluid mb-3 mb-lg-0" src="https://s3.amazonaws.com/prod-www-blackline-com/blog/wp-content/uploads/2019/01/29163118/A-Conversation-With-BlackLines-Machine-Learning-Experts.jpg" alt="">
  </div>
  <div class="col-xl-4 col-lg-5">
      <div class="featured-text text-center text-lg-left">
      <h4>Machine learning paper</h4>
      <p class="text-black-50 mb-0">One of the papers worked, was the use of machine learning inorder to find code vulnerabilities by a Neural Network model. This model used RNN and text processing and the paper can be found <a href="https://www.sciencedirect.com/science/article/abs/pii/S0957417419308735">here</a>.</p>
      </div>
  </div>
</div>
